<template>
  <div class="CountDetail">
    <a ref="download" style="display: none" />
    <h2>考勤明细</h2>
    <div class="searchWrap">
      <el-row>
        <el-col :span="4">
          <div class="sb">
            <span>出勤日期</span>
            <el-date-picker
              clearable
              size="small"
              v-model="attendanceDate"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              placeholder="选择月">
            </el-date-picker>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>姓名</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="workerName"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>身份证号</span>
            <el-input
              class="searchInput"
              size="small"
              placeholder="请输入内容"
              v-model="idCard"
              clearable
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>企业</span>
            <el-select
              v-model="corpId"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in corpList"
                :key="item.corpId"
                :label="item.corpNmae"
                :value="item.corpId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>班组</span>
            <el-select
              v-model="teamId"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in teamList"
                :key="item.teamId"
                :label="item.teamName"
                :value="item.teamId"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="sb">
            <span>考勤状态</span>
            <el-select
              v-model="attendUs"
              clearable
              class="searchInput"
              size="small"
              placeholder="请选择"
            >
              <el-option label="考勤" value="0"></el-option>
              <el-option label="未考勤" value="1"></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="small" @click="onGetListData"
            >搜索</el-button
          >
          <el-button size="small" @click="handleExport" :disabled="onCheckBtnAuthority('CountDetailExport')"
            >导出当前数据</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table :data="listData" height="759" style="margin-top: 20px">
      <el-table-column fixed prop="workerName" label="姓名"> </el-table-column>
      <el-table-column
        v-for="(index, item) in 31"
        :label="(item+1)+''"
        :key="index"
        width="100"
      >
        <template slot-scope="scope">
          <div>{{ format(0, scope.row['day'+( item+1<10 ? '0'+(item+1) : (item+1))]) }}</div>
          <div>{{ format(1, scope.row['day'+( item+1<10 ? '0'+(item+1) : (item+1))]) }}</div>
        </template>  
    </el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next"
      :total="totals"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onGetListData"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 200, 500]"
    >
    </el-pagination>
  </div>
</template>
    
<style lang="less" scoped>
.CountDetail {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "CountDetail",
  components: {},
  data() {
    return {
      pageBtn: [],
      pageSize: 10,
      pageNum: 1,
      totals: 0,
      listData: [],
      attendanceDate: "",
      workerName: "",
      idCard: "",
      corpId: "",
      teamId: "",
      attendUs: "",
      corpList: [],
      teamList: [],
    };
  },
  watch: {
    corpId: function () {
      this.pageNum = 1
      this.teamId = ""
      this.teamList = []
      this.onGetListData();
      this.onGetTeamList();
    },
    teamId: function () {
      this.pageNum = 1
      this.onGetListData();
    },
    attendUs: function () {
      this.pageNum = 1
      this.onGetListData();
    },
  },
  computed: {},
  mounted() {
    this.onGetPageBtn();
    this.onGetListData();
    this.onGetCorpList();
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取当前页面按钮权限
    onGetPageBtn: function () {
      var that = this;
      var data = {
        id: "1688761297964396545",
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/menu/buttons",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.pageBtn = res.data;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 检查按钮权限
    onCheckBtnAuthority: function (code) {
      var disable = true;
      if (this.pageBtn.length) {
        this.pageBtn[0].children.forEach((item) => {
          if (item.code == code) {
            disable = false;
          }
        });
      }
      return disable;
    },
    // 获取当前年月
    getNowDate: function () {
      let date = new Date()
      var year = date.getFullYear()
      var month = date.getMonth()+1
      if(month<10) month = `0${month}`
      this.attendanceDate = year + '-' + month
    },
    // 获取主列表数据
    onGetListData: function () {
      if (this.attendanceDate == "" || this.attendanceDate == null) {
        this.getNowDate()
      }
      var that = this;
      var data = {
        projectId: localStorage.getItem('projectId'),
        attendanceDate: this.attendanceDate, // 必传  出勤日期
        workerName: this.workerName, // 姓名
        idCard: this.idCard, // 身份证号
        corpId: this.corpId, // 企业id
        teamId: this.teamId, // 班组id
        attendUs: this.attendUs, // 班组id
        pageNum: this.pageNum, // 必传
        pageSize: this.pageSize, // 必传
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/kqRealtimeStatistics/attendanceDetail",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.listData = res.data.dataList;
            that.totals = res.data.totals;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换分页大小
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.onGetListData();
    },
    // 获取参建单位列表
    onGetCorpList: function () {
      var that = this;
      var data = {
        projectId: localStorage.getItem("projectId"),
        pageSize: 10000,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectSubContractor/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.corpList = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取部门班组列表
    onGetTeamList: function () {
      var that = this;
      if (!this.corpId || this.corpId == "") { return }
      var data = {
        projectId: localStorage.getItem("projectId"),
        corpId: this.corpId,
        pageSize: 10,
        pageNum: 1,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-workers/realProjectTeam/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.teamList = res.data.dataList;
          } else {
            that.$message(res.msg)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 抽取打卡时间
    format: function (index, str) {
      var arr = JSON.parse(str)
      if (arr[index] == '-') {
        return arr[index]
      } else {
        return arr[index]
        // return arr[index].split(' ')[1]
      }
    },
    // 导出
    handleExport: function () {
      var that = this;
      var timestamp = new Date().getTime()
      var data = {
        projectId: localStorage.getItem('projectId'),
        dateTime : this.attendanceDate,
        workerName: this.workerName,
        idCard: this.idCard,
        corpId: this.corpId,
        teamId: this.teamId,
        attendUs: this.attendUs,
      };
      that
        .$axiosAdmin({
          method: "post",
          responseType: "blob",
          url: "api/qingxin-platform-workers/ExcelImport/MonthKQExcelExport",
          data: JSON.stringify(data),
        })
        .then((res) => {
          const blob = res;
          that.$refs.download.href = window.URL.createObjectURL(new Blob([blob], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          that.$refs.download.download = `${that.attendanceDate}考勤明细 ${timestamp}.xlsx`;
          that.$refs.download.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    